import React from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit';
import pic from '../../assets/two/app.png';

const Faq = () => {
  return (
    <section
      id='faq'
      className='bg-white'
      style={{
        borderRadius: 24,
        boxShadow: '0 0 10px rgba(0,0,0,.15)',
      }}
    >
      <div className='container'>
        <div className='s-80'></div>
        <h3 className='title two-title ml-10'>FAQ</h3>
        {/*<div className='s-10'></div>*/}
        <div className='row py-4'>
          <div className='col-lg-6'>
            <MDBContainer className='mt-5'>
              <MDBAccordion alwaysOpen initialActive={1}>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle='What age group is KidsBank suitable for?'
                >
                  KidsBank is designed for children aged 5-12, providing a safe
                  and supervised platform for trading toys.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle='How do parents monitor transactions?'
                >
                  Parents can easily oversee all transactions through the app’s
                  parental control features, ensuring every trade and purchase
                  is secure.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle='Is the app free to use?'
                >
                  Yes, KidsBank is free to download and use, with no hidden
                  fees. Optional premium features are available for enhanced
                  functionality.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={4}
                  headerTitle='What types of toys can be traded?'
                >
                  Kids can trade a wide range of toys, including action figures,
                  dolls, educational games, and more, all listed by other kids
                  in the community.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={5}
                  headerTitle='How does KidsBank ensure secure transactions?'
                >
                  All transactions are monitored and protected with advanced
                  security measures, including encryption and parental
                  approvals, to ensure a safe trading environment.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={6}
                  headerTitle='Can kids use KidsBank without parental supervision?'
                >
                  No, KidsBank requires parental supervision for all activities,
                  ensuring that every trade and purchase is safe and
                  appropriate.
                </MDBAccordionItem>
              </MDBAccordion>
            </MDBContainer>
          </div>
          <div className='col-lg-6'>
            <div className='m-20'></div>
            <img
              src={pic}
              alt='Features'
              width={600}
              height={456}
              className='w-100'
            />
          </div>
        </div>
        <div className='s-60'></div>
      </div>
    </section>
  );
};

export { Faq };
