import React from 'react';

const Footer = () => {
  const year = new Date();
  return (
    <footer id='contact' className='bg-white'>
      <div className='container py-5'>
        <div className='s-20'></div>
        <div className='row'>
          <div className='col-lg-6 py-3'>
            <p className='c-dark title-mini'>
              <a href='/'>@{year.getFullYear()} - KidsBank</a>. All Rights
              Reserved.
            </p>
          </div>
          <div className='col-lg-6 py-3 tr'>
            <a href='/privacy-policy' className='c-dark title-mini'>
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
