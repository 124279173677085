import React, { useState, useEffect } from 'react';
import b1 from '../../assets/three/buttons/google.svg';
import b2 from '../../assets/three/buttons/email.svg';

const Form = () => {
  const [email, setEmail] = useState('');
  const [validated, setValidated] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.currentTarget.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Handle form submission
      console.log('Form submitted with email:', email);
    }
    setValidated(true);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth < 768; 
  const inputStyle = {
    width: isMobile ? '88%' : '96%',
  };

  return (
    <div
      className='r-24 px-4 py-3 shadow'
      style={{ backgroundColor: '#F4F0FC' }}
    >
      <h3 className='title three-title primary'>Join Now</h3>
      <p className='three-text dark'>Start Trading Today!</p>
      <form noValidate validated={validated.toString()} onSubmit={handleSubmit}>
        <button type='button' className='three-button'>
          <img
            src={b1}
            alt='Continue with Google'
            aria-label='button'
            width={416}
            height={61}
            className='w-100'
          />
        </button>
        <p className='three-text dark'>or</p>
        <div className='form-group'>
          <input
            type='email'
            className='form-control ma'
            style={inputStyle}
            placeholder='Email'
            value={email}
            onChange={handleEmailChange}
            required
          />
          <div className='invalid-feedback'>
            Please enter a valid email address.
          </div>
        </div>
        <button type='submit' className='three-button'>
          <img
            src={b2}
            alt='Continue with Email'
            aria-label='button'
            width={416}
            height={61}
            className='w-100'
          />
        </button>
        <p>
          <small className='three-text dark'>
            By continuing you agree to our
            <br />
            Terms of Service and Privacy Policy
          </small>
        </p>
      </form>
    </div>
  );
};

export { Form };
