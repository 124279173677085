import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Form = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    comments: '',
  });

  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    comments: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Simple validation logic
    switch (name) {
      case 'fullName':
        setErrors({
          ...errors,
          fullName: value ? '' : 'Full Name is required',
        });
        break;
      case 'email':
        const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        setErrors({
          ...errors,
          email: emailValid ? '' : 'Email is invalid',
        });
        break;
      case 'comments':
        setErrors({
          ...errors,
          comments: value ? '' : 'Comments are required',
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.fullName && formData.email && formData.comments) {
      // Form submission logic here
      console.log('Form submitted:', formData);
    } else {
      alert('Please fill out all fields correctly.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className='container mt-5'>
      <div className='form-group'>
        <label htmlFor='fullName'>Full Name</label>
        <input
          type='text'
          className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
          id='fullName'
          name='fullName'
          value={formData.fullName}
          onChange={handleChange}
        />
        {errors.fullName && (
          <div className='invalid-feedback'>{errors.fullName}</div>
        )}
      </div>
      <div className='form-group my-3'>
        <label htmlFor='email'>Email</label>
        <input
          type='email'
          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
          id='email'
          name='email'
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
      </div>
      <div className='form-group my-3'>
        <label htmlFor='comments'>Comment</label>
        <textarea
          className={`form-control ${errors.comments ? 'is-invalid' : ''}`}
          id='comments'
          name='comments'
          rows='3'
          value={formData.comments}
          onChange={handleChange}
        ></textarea>
        {errors.comments && (
          <div className='invalid-feedback'>{errors.comments}</div>
        )}
      </div>
      <button type='submit' className='button b-subscribe'>
        Subscribe to demo
      </button>
    </form>
  );
};

export { Form };
