import React, { useState } from 'react';
import logo from '../../logo.svg';

const Nav = () => {
  const [opened, setOpened] = useState(true);

  return (
    <section id='top' className='land-two'>
      <div className='container'>
        <header className='flex jcsb aic h-10'>
          <h1 id='logo'>
            <div className='s-10'></div>
            <a href='/' aria-label='Logotype'>
              <img
                src={logo}
                width={174}
                height={52}
                alt='template'
                style={{ transform: 'translateY(-3px)' }}
              />
            </a>
          </h1>
          <div
            className={opened ? 'burger' : 'burger open'}
            onClick={() => setOpened(!opened)}
          >
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a
              href='#features'
              className='mx-3 px-1 two-font'
              onClick={() => setOpened(!opened)}
            >
              Features
            </a>
            <a
              href='#benefits'
              className='mx-3 px-1 two-font'
              onClick={() => setOpened(!opened)}
            >
              Benefits
            </a>
            <a
              href='#faq'
              className='mx-3 px-1 two-font'
              onClick={() => setOpened(!opened)}
            >
              FAQ
            </a>
            <a
              href='#contact'
              className='mx-3 px-1 two-font vm'
              onClick={() => setOpened(!opened)}
            >
              Subscribe to demo
            </a>
          </div>
          <div className='vd'>
            <a
              href='#contact'
              className='button b-two'
              onClick={() => setOpened(!opened)}
            >
              Subscribe to demo
            </a>
          </div>
        </header>
      </div>
    </section>
  );
};

export { Nav };
