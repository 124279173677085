import React from 'react';
import ios from '../../assets/two/appstore.png';
import android from '../../assets/two/googleplay.png';
import pic from '../../assets/two/intro.png';

const Intro = () => {
  return (
    <section id='intro' className='land-two'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='s-100'></div>
            <h2 className='title-intro two-title'>
              Transform your child's toy trading experience
            </h2>
            <div className='s-20'></div>
            <p className='title two-text lh-175'>
              Join KidsBank, the ultimate app for kids to buy, sell, and trade
              toys safely under parental supervision
            </p>
            <div className='s-40'></div>
            <div>
              <a href='#contact'>
                <img
                  src={ios}
                  width={174}
                  height={52}
                  alt='Download on the App Store'
                />
              </a>
              <span className='px-2'></span>
              <a href='#contact'>
                <img
                  src={android}
                  width={174}
                  height={52}
                  alt='Get It on Google Play'
                />
              </a>
            </div>
          </div>
          <div className='col-lg-6 flex aic h-90'>
            <img
              src={pic}
              alt='KidsBank'
              height={500}
              className='w-95'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export { Intro };
