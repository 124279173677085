import React from 'react';
import i1 from '../../assets/one/u1.jpg';
import i2 from '../../assets/one/u2.jpg';
import i3 from '../../assets/one/u3.jpg';

const Reviews = () => {
  return (
    <section id='reviews' className='w-100 py-5 land-one'>
      <div className='s-40'></div>
      <div className='container tc'>
        <h3 className='title one-title c-white'>Happy Users</h3>
        <div className='s-40 m-40'></div>
        <div className='row'>
          <div className='col-lg-4'>
            <div
              className='r-24 px-5 py-3 cr-blue'
              style={{ backgroundColor: '#6E6DA4' }}
            >
              <div className='s-30'></div>
              <img
                src={i1}
                alt='Jack Spark'
                width={120}
                height={120}
                className='r-48'
              />
              <div className='s-20'></div>
              <p className='title-mini c-white one-font'>Jack Spark, Parent</p>
              <p className='title-part c-white one-font'>
                “My kids love trading their toys safely!”
              </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='r-24 px-5 py-3 cr-blue'
              style={{ backgroundColor: '#6E6DA4' }}
            >
              <div className='s-30'></div>
              <img
                src={i2}
                alt='Emily Robbins'
                width={120}
                height={120}
                className='r-48'
              />
              <div className='s-20'></div>
              <p className='title-mini c-white one-font'>
                Emily Robbins, Parent
              </p>
              <p className='title-part c-white one-font'>
                “Safe, fun, and educational for my kids!”
              </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='r-24 px-5 py-3 cr-blue'
              style={{ backgroundColor: '#6E6DA4' }}
            >
              <div className='s-30'></div>
              <img
                src={i3}
                alt='Ken Miley'
                width={120}
                height={120}
                className='r-48'
              />
              <div className='s-20'></div>
              <p className='title-mini c-white one-font'>Ken Miley, Parent</p>
              <p className='title-part c-white one-font'>
                “A fantastic way to teach financial skills.”
              </p>
            </div>
          </div>
        </div>
        <div className='s-40'></div>
      </div>
    </section>
  );
};

export { Reviews };
