import React, { useEffect, useState } from 'react';
import { Form } from '../../components/Two/Form';
import pic from '../../assets/two/screen.jpg';

const Contact = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth < 768;


  return (
    <section id='contact' className='bg-tr'>
      <div className='container'>
        <div className='s-80'></div>
        <div className='row py-4'>
          <div className='col-lg-6'>
            <div className='s-70'></div>
            {isMobile && <div className='s-30'></div>}

            <img
              src={pic}
              alt='Benefits'
              width={'100%'}
              height={'77.5%'}
              className='obfc r-24 up40'
            />
          </div>
          <div className='col-lg-6'>
            <h3 className='title two-title px-2'>Interested?</h3>
            <div className='s-10'></div>
            <p className='two-text px-2'>
              We’re thrilled to announce that our innovative toy marketplace app is on the way!
            </p>

            <p className='two-text px-2'>
              Enter your email address below to stay informed and be part of our journey. We can’t wait to share this exciting adventure with you!
            </p>

            <Form />
          </div>
        </div>
        <div className='s-100'></div>
      </div>
    </section>
  );
};

export { Contact };