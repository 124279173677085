import React from 'react';
import b1 from '../../assets/two/icons/b1.svg';
import b2 from '../../assets/two/icons/b2.svg';
import b3 from '../../assets/two/icons/b3.svg';
import pic from '../../assets/two/benefits.jpg';

const Benefits = () => {
  return (
    <section id='benefits' className='bg-tr'>
      <div className='container'>
        <div className='s-80'></div>
        <h3 className='title two-title'>Benefits</h3>
        <div className='s-10'></div>
        <div className='row py-4'>
          <div className='col-lg-6'>
            <img
              src={pic}
              alt='Benefits'
              width={'100%'}
              height={'83.5%'}
              className='obfc r-24'
              style={{ transform: 'translateY(3px)' }}
            />
          </div>
          <div className='col-lg-6'>
            <div
              className='two-card white p-3 my-1'
              style={{
                borderRadius: '24px 24px 0 0',
              }}
            >
              <h3>
                <img src={b1} alt='Easy to use' width={24} height={24} />
                <span className='title-sub two two-text px-2'>Easy to use</span>
              </h3>
              <p className='c-black px-1'>
                Our app is designed with simplicity in mind, making it easy for
                kids to navigate and use with minimal assistance. Parents can
                effortlessly manage accounts and oversee transactions.
              </p>
            </div>
            <div className='s-20'></div>
            <div className='two-card white p-3 my-1'>
              <h3>
                <img
                  src={b2}
                  alt='Diverse toy selection'
                  width={24}
                  height={24}
                />
                <span className='title-sub two two-text px-2'>
                  Diverse toy selection
                </span>
              </h3>
              <p className='c-black px-1'>
                From action figures to educational games, explore a vast
                selection of toys offered by other kids. Easily find and
                exchange the toys your child loves while discovering new
                favorites.
              </p>
            </div>
            <div className='s-20'></div>
            <div
              className='two-card white p-3 my-1'
              style={{
                borderRadius: '0 0 24px 24px',
              }}
            >
              <h3>
                <img
                  src={b3}
                  alt='Secure transactions'
                  width={24}
                  height={24}
                />
                <span className='title-sub two two-text px-2'>
                  Secure transactions
                </span>
              </h3>
              <p className='c-black px-1'>
                Enjoy peace of mind with secure, parent-monitored transactions.
                Our app ensures that all trades and purchases are safe and
                reliable, with robust protection for your child’s information
                and activities.
              </p>
            </div>
          </div>
        </div>
        <div className='s-60'></div>
      </div>
    </section>
  );
};

export { Benefits };
