import React from 'react';
import f1 from '../../assets/three/icons/f1.svg';
import f2 from '../../assets/three/icons/f2.svg';
import f3 from '../../assets/three/icons/f3.svg';
import f4 from '../../assets/three/icons/f4.svg';
import f5 from '../../assets/three/icons/f5.svg';
import f6 from '../../assets/three/icons/f6.svg';

const Features = () => {
  return (
    <section id='features' className='bg-tr'>
      <div className='s-70'></div>
      <div className='container'>
        <div className='tc'>
          <div className='m-20'></div>
          <h3 className='title three-title c-three'>Our Advantages</h3>
          <div className='s-20'></div>
          <p className='title three-text dark px-1 w-60 ma'>
            Ensuring anonymity and security. Smooth learning process. Friendly
            community of parents and children
          </p>
        </div>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3>
              <img src={f1} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three dark'>Safety</span>
            </h3>
            <p className='three-text px-5'>
              Kids’re secured by parental control and AI
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3>
              <img src={f2} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three dark'>Financial Literacy</span>
            </h3>
            <p className='three-text px-5'>
              Kids learn money management skills.
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3>
              <img src={f3} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three dark'>Eco-Friendly</span>
            </h3>
            <p className='three-text px-5'>Reduce waste by exchanging toys.</p>
          </div>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3>
              <img src={f4} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three dark'>Fun & Engaging</span>
            </h3>
            <p className='three-text px-5'>Make trading toys fun for kids.</p>
          </div>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3>
              <img src={f5} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three dark'>Community</span>
            </h3>
            <p className='three-text px-5'>
              Join a community of responsible parents.
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3>
              <img src={f6} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three dark'>Easy to Use</span>
            </h3>
            <p className='three-text px-5'>
              Simple interface for kids and parents.
            </p>
          </div>
        </div>
        <div className='s-80'></div>
      </div>
    </section>
  );
};

export { Features };
