import React from 'react';
import { Nav } from '../components/One/Nav';
import { Intro } from './partsOne/Intro';
import { About } from './partsOne/About';
import { Reviews } from './partsOne/Reviews';
import { Join } from './partsOne/Join';
import { Footer } from '../components/One/Footer';

const back = '#363062';

const LandOne = () => {
  return (
    <div style={{ backgroundColor: back }}>
      <Nav />
      <Intro />
      <About />
      <Reviews />
      <Join />
      <Footer />
    </div>
  );
};

export { LandOne };
