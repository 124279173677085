import React from 'react';

const Faq = () => {
  return (
    <section id='faq' className='bg-white'>
      <div className='container'>
        <div className='s-80'></div>
        <div className='m-40'></div>
        <h3 className='title three-title c-three'>FAQ</h3>
        {/*<div className='s-10'></div>*/}
        <p className='title three-text dark'>
          Frequently asked questions about KidsBank
        </p>
        <div className='s-20'></div>
        <div className='row py-4'>
          <div className='col-lg-4 my-3'>
            <div className='three-card px-4 py-2 r-16 h-20'>
              <h3 className='title three-title cards py-3'>
                How do I sign up?
              </h3>

              <p className='three-text'>
                Sign up by entering your email and creating a password.
              </p>
            </div>
          </div>
          <div className='col-lg-4 my-3'>
            <div className='three-card px-4 py-2 r-16 h-20'>
              <h3 className='title three-title cards py-3'>
                How is safety ensured?
              </h3>

              <p className='three-text'>
                Parents monitor all activities and approve each trade.
              </p>
            </div>
          </div>
          <div className='col-lg-4 my-3'>
            <div className='three-card px-4 py-2 r-16 h-20'>
              <h3 className='title three-title cards py-3'>
                Can kids trade anonymously?
              </h3>

              <p className='three-text'>
                Yes, kids' identities are kept anonymous during trades.
              </p>
            </div>
          </div>
          <div className='col-lg-4 my-3'>
            <div className='three-card px-4 py-2 r-16 h-20'>
              <h3 className='title three-title cards py-3'>
                What age is this for?
              </h3>

              <p className='three-text'>
                Suitable for kids aged 5-12 with parental guidance.
              </p>
            </div>
          </div>
          <div className='col-lg-4 my-3'>
            <div className='three-card px-4 py-2 r-16 h-20'>
              <h3 className='title three-title cards py-3'>
                How do I list a toy?
              </h3>

              <p className='three-text'>
                Simply take a picture and add it to your profile.
              </p>
            </div>
          </div>
          <div className='col-lg-4 my-3'>
            <div className='three-card px-4 py-2 r-16 h-20'>
              <h3 className='title three-title cards py-3'>Is it free?</h3>

              <p className='three-text'>
                Yes, the app is free to use with no hidden charges.
              </p>
            </div>
          </div>
        </div>
        <div className='s-60'></div>
      </div>
    </section>
  );
};

export { Faq };
