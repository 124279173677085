import React from 'react';
import { Nav } from '../components/Three/Nav';
import { Intro } from '../pages/partsThree/Intro';
import { About } from './partsThree/About';
import { Features } from './partsThree/Features';
import { Join } from './partsThree/Join';
import { Faq } from './partsThree/Faq';
import { Footer } from '../components/Three/Footer';

const LandThree = () => {
  return (
    <div style={{ backgroundColor: '#F4F0FC' }} className='ldnthree'>
      <Nav />
      <Intro />
      <About />
      <Features />
      <Join />
      <Faq />
      <Footer />
    </div>
  );
};

export { LandThree };
