import React from 'react';
import c1 from '../../assets/one/c1.jpg';
import c2 from '../../assets/one/c2.jpg';
import c3 from '../../assets/one/c3.jpg';
import c4 from '../../assets/one/c4.jpg';
import c5 from '../../assets/one/c5.jpg';
import c6 from '../../assets/one/c6.jpg';

const Join = () => {
  return (
    <section
      id='join'
      className='w-100 py-5 land-one'
      style={{ backgroundColor: '#2C2752' }}
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='s-60'></div>
            <h3 className='title one-title c-white w-60'>Join Our Community</h3>
            <div className='s-20'></div>
            <p className='c-white one-font'>
              Become a part of a safe and fun toy trading community.
            </p>
            <div className='s-10'></div>
            <a href='/' className='button b-one r-24'>
              Get Started
            </a>
          </div>
          <div className='m-40'></div>
          <div className='col-lg-6'>
            <div className='row'>
              <div className='col-lg-4'>
                <img
                  src={c1}
                  alt='community 1'
                  width={160}
                  height={160}
                  className='my-3 r-48'
                />
              </div>
              <div className='col-lg-4'>
                <img
                  src={c2}
                  alt='community 2'
                  width={160}
                  height={160}
                  className='my-3 r-48'
                />
              </div>
              <div className='col-lg-4'>
                <img
                  src={c3}
                  alt='community 3'
                  width={160}
                  height={160}
                  className='my-3 r-48'
                />
              </div>
              <div className='col-lg-4'>
                <img
                  src={c4}
                  alt='community 4'
                  width={160}
                  height={160}
                  className='my-3 r-48'
                />
              </div>
              <div className='col-lg-4'>
                <img
                  src={c5}
                  alt='community 5'
                  width={160}
                  height={160}
                  className='my-3 r-48'
                />
              </div>
              <div className='col-lg-4'>
                <img
                  src={c6}
                  alt='community 6'
                  width={160}
                  height={160}
                  className='my-3 r-48'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Join };
