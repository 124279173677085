import React from 'react';
import pic1 from '../../assets/one/h1.jpg';
import pic2 from '../../assets/one/h2.jpg';
import pic3 from '../../assets/one/h3.jpg';
import i1 from '../../assets/one/i1.svg';
import i2 from '../../assets/one/i2.svg';
import i3 from '../../assets/one/i3.svg';

const About = () => {
  return (
    <section id='about' className='land-one'>
      <div style={{ backgroundColor: '#2C2752' }}>
        <div className='s-80 m-40'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <h4 className='title-sub one-title c-yellow'>Our Vision</h4>
              <div className='s-10'></div>
              <h3 className='title one-title c-white w-80'>
                Empowering Kids Through Fun
              </h3>
            </div>
            <div className='col-lg-6'>
              <div className='s-60'></div>
              <p className='c-white one-font'>
                Teaching financial literacy through play.
              </p>
              <div className='m-40'></div>
            </div>
          </div>
        </div>
        <div className='s-100 m-40'></div>
      </div>
      <div className='container tc'>
        <div className='s-80  m-40'></div>
        <h3 className='title one-title c-white'>How It Works</h3>
        <div className='s-10'></div>
        <p className='c-white one-font'>
          Learn how to use our app effortlessly
        </p>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='m-40'></div>
            <img
              src={pic1}
              alt='Safe and Secure'
              width={360}
              height={224}
              className='w-100 r-24 obfc'
            />
            <div className='s-20'></div>
            <p className='title-mini c-yellow one-font'>Step 1</p>
            <p className='title-part c-white one-font'>Safe and Secure</p>
            <p className='title-mini c-white one-font'>
              Parents moderate all transactions
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='m-40'></div>
            <img
              src={pic2}
              alt='Financial Literacy'
              width={360}
              height={224}
              className='w-100 r-24'
            />
            <div className='s-20'></div>
            <p className='title-mini c-yellow one-font'>Step 2</p>
            <p className='title-part c-white one-font'>Financial Literacy</p>
            <p className='title-mini c-white one-font'>
              Kids learn while trading toys
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='m-40'></div>
            <img
              src={pic3}
              alt='Fun and Engaging'
              width={360}
              height={224}
              className='w-100 r-24'
            />
            <div className='s-20'></div>
            <p className='title-mini c-yellow one-font'>Step 3</p>
            <p className='title-part c-white one-font'>Fun and Engaging</p>
            <p className='title-mini c-white one-font'>
              Interactive and educational activities.
            </p>
          </div>
        </div>
        <div className='s-40'></div>
      </div>
      <div className='s-60'></div>
      <div style={{ backgroundColor: '#2C2752' }}>
        <div className='container tc'>
          <div className='s-80 m-40'></div>
          <h3 className='title one-title c-white'>Why Choose Us?</h3>
          <div className='s-10'></div>
          <p className='c-white one-font'>Safety, Fun, and Learning Combined</p>
          <div className='s-40'></div>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='bg-yellow r-24 h-30'>
                <div className='s-30'></div>
                <img src={i1} alt='Parental Control' width={40} height={40} />
                <div className='s-20'></div>
                <p className='title-part c-onetheme one-font'>
                  Parental Control
                </p>
                <p className='title-mini c-onetheme one-font'>
                  Parents oversee all activities for safety
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='bg-yellow r-24 h-30'>
                <div className='s-30'></div>
                <img src={i2} alt='Anonymity' width={40} height={40} />
                <div className='s-20'></div>
                <p className='title-part c-onetheme one-font'>Anonymity</p>
                <p className='title-mini c-onetheme one-font'>
                  Kids' identities remain private
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='bg-yellow r-24 h-30'>
                <div className='s-30'></div>
                <img src={i3} alt='Eco-Friendly' width={40} height={40} />
                <div className='s-20'></div>
                <p className='title-part c-onetheme one-font'>Eco-Friendly</p>
                <p className='title-mini c-onetheme one-font'>
                  Reduce toy wastage by trading
                </p>
              </div>
            </div>
          </div>
          <div className='s-80 m-40'></div>
        </div>
      </div>
    </section>
  );
};

export { About };
