import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { LandOne } from './pages/LandOne';
import { LandTwo } from './pages/LandTwo';
import { LandThree } from './pages/LandThree';

import './styles/custom.sass';

function App() {
  useEffect(() => {
    TagManager.initialize({
      gtmId: 'G-WVS8C0M7V3',
    });
    TagManager.initialize({
      gtmId: 'G-WVS8C0M7V3',
      dataLayer: {
        js: new Date(),
        config: 'G-WVS8C0M7V3',
      },
    });
  }, []);
  const randomNumber = Math.floor(Math.random() * 3) + 1;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            randomNumber === 1 ? (
              <LandTwo />
            ) : randomNumber === 2 ? (
              <LandThree />
            ) : (
              <LandOne />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export { App };
