import React from 'react';

const Intro = () => {
  return (
    <section id='intro' className='land-one'>
      <div className='container tc'>
        <h2 className='title-intro one-title c-white w-60 ma'>
          Empowering Kids, One Trade at a Time!
        </h2>
        <div className='s-20'></div>
        <p className='c-white text-intro one-font'>
          Join our safe and fun marketplace.
        </p>
        <div className='s-30'></div>
        <a className='button b-one' href='/'>
          Download Now
        </a>
      </div>
    </section>
  );
};

export { Intro };
