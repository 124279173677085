import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import 'bootstrap/dist/css/bootstrap.min.css';
import { App } from './App';

ReactGA.initialize('G-WVS8C0M7V3');
ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
