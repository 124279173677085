import React from 'react';
import { Form } from '../../components/Three/Form';
import get from '../../assets/three/buttons/get.svg';

const Join = () => {
  return (
    <>
      <section id='join' className='three-landing'>
        <div className='container tc'>
          <div className='s-100'></div>
          <div className='s-40'></div>
          <div className='row'>
            <div className='col-lg-4'></div>
            <div className='col-lg-4'>
              <Form />
            </div>
            <div className='col-lg-4'></div>
          </div>
          <div className='s-100'></div>
          <div className='s-40'></div>
        </div>
      </section>
      <section id='start' className='bg-tr'>
        <div className='s-100'></div>
        <div className='s-40'></div>
        <div className='m-40'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <h3 className='title three-title mini'>
                Get early access and join our toy trading community
              </h3>
              <div className='s-20'></div>
              <p className='three-text w-80'>
                Join our community today and help reduce toy overage while
                providing a valuable learning experience for your child.
              </p>
              <div className='s-20'></div>
              <a href='/'>
                <img
                  src={get}
                  alt='Get the App'
                  aria-label='button'
                  width={125}
                  height={48}
                />
              </a>
            </div>
            <div className='col-lg-6'></div>
          </div>
        </div>
        <div className='m-40'></div>
        <div className='s-100'></div>
        <div className='s-40'></div>
      </section>
    </>
  );
};

export { Join };
