import React from 'react';
import { Nav } from '../components/Two/Nav';
import { Intro } from '../pages/partsTwo/Intro';
import { Features } from './partsTwo/Features';
import { Benefits } from './partsTwo/Benefits';
import { Faq } from './partsTwo/Faq';
import { Contact } from './partsTwo/Contact';
import { Footer } from '../components/Two/Footer';

const LandTwo = () => {
  return (
    <div style={{ backgroundColor: '#F3EDFF' }} className='ldntwo'>
      <Nav />
      <Intro />
      <Features />
      <Benefits />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
};

export { LandTwo };
