import React from 'react';
import a1 from '../../assets/three/icons/a1.svg';
import a2 from '../../assets/three/icons/a2.svg';
import a3 from '../../assets/three/icons/a3.svg';
import pic from '../../assets/three/about.jpg';

const About = () => {
  return (
    <section id='About' className='bg-white'>
      <div className='container'>
        <div className='row py-4'>
          <div className='col-lg-6'>
            <img
              src={pic}
              alt='How It Works'
              width={'100%'}
              height={'80%'}
              className='obfc r-24 mh-100'
            />
          </div>
          <div className='col-lg-1'></div>
          <div className='col-lg-4'>
            <div className='s-30'></div>
            <h3 className='title three-title'>How It Works</h3>
            <div className='s-20'></div>
            <p className='three-title'>
              Our project aims to not only facilitate toy trading but also
              foster financial literacy among children while ensuring a safe and
              supportive environment for all participants. Join now to be part
              of this innovative initiative! Here are the simple steps to trade
              toys on our platform:
            </p>
          </div>
          <div className='col-lg-1'></div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3 className='tl'>
              <img src={a1} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three'>Sign Up</span>
            </h3>
            <p className='three-text py-3'>
              Create your account today to start trading and join now to
              collectively help solve the toys overage problem by exchanging
              toys in a secure environment.
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3 className='tl'>
              <img src={a2} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three'>List a Toy</span>
            </h3>
            <p className='three-text py-3'>
              Put your unused toys up for sale. Teach your kids the value of
              money while decluttering your home. Our platform ensures a safe
              and child-friendly trading experience.
            </p>
          </div>
          <div className='col-lg-4'>
            <div className='s-10'></div>
            <h3 className='tl'>
              <img src={a3} alt='Easy to use' width={32} height={32} />
              <span className='px-2 hide'></span>
              <span className='title-sub three'>Approve Trade</span>
            </h3>
            <p className='three-text py-3'>
              Parents approve the trade request. This ensures every transaction
              is safe and appropriate for kids. Empower your children to learn
              financial literacy while keeping their interactions secure.
            </p>
          </div>
        </div>
        <div className='s-80'></div>
      </div>
    </section>
  );
};

export { About };
